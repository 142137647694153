.password-form {
    max-width: 450px;
}
.settings-form {
    max-width: 450px;
}
.field {
    display: block;
    margin: 20px 0px;
}
.field label {
    font-family: "AvertaRegular";
    color: var(--dark);
}
.field .checkbox {
    display: block;
    margin-bottom: 35px;
}
.field .form-field {
    margin: 2px 0px;
}
.signature-block {
    width: 500px;
    max-width: 100%;
}
.cancel {
    font-family: "AvertaSemiBold";
    padding: 10px 15px;
    background-color: var(--lightRed);
    color: var(--red);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
    margin-right: 10px;
}
.save {
    font-family: "AvertaSemiBold";
    padding: 10px 15px;
    background-color: var(--lightGreen);
    color: var(--green);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
.change {
    font-family: "AvertaSemiBold";
    padding: 10px 15px;
    background-color: var(--lightOrange);
    color: var(--orange);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
.canvas-container {
    position: relative;
    padding-bottom: 50%; /* Set the aspect ratio of the canvas (height / width) */
    width: 100%; /* Set the width to 100% of the parent container */
}
.canvas {
    border: 2px dashed black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.signature-image {
    max-width: 100%;
    border: 2px solid white;
}