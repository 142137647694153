.info-block {
    background-color: var(--gray);
    border: 1px solid var(--gray);
    margin-bottom: 25px;
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.info-block:hover {
    cursor: pointer;
    border: 1px solid var(--dark);
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.info-block-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
    padding: 25px;
}
.info-block-content .count {
    font-family: 'AvertaBold';
    font-size: 32px;
    color: var(--dark);
}
.info-block-content h3 {
    margin: 0px;
    color: var(--dark);
    opacity: 0.35;
}