#bottomnav {
    background-color: white;
    position: fixed;
    width: calc(100% - 20px);
    bottom: 0px;
    z-index: 20;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    padding: 10px 20px;
}
.bottom-menu-items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 20px);
}
.bottom-menu-items .item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 18%;
    color: var(--dark);
    padding: 10px;
    font-size: 13px;
    border-radius: 10px;
    text-decoration: none;
    font-family: "AvertaRegular";
}
.bottom-menu-items .item.current {
    background-color: var(--middleRed);
    color: var(--red);
}
.bottom-menu-items .item svg {
    font-size: 18px;
    margin-bottom: 5px;
}

/* iPad Landscape */
@media only screen and (min-width: 1024px) {
    #bottomnav {
        display: none;
    }
}