#thanks-offerte {
    height: 100vh;
    background-color: #F4F0E0;;
}
#thanks-offerte .container {
    padding: 20px 0px;
}
#thanks-offerte .thanks-content {
    position: relative;
    z-index: 5;
    margin: 35% 20px 0px 20px;
}
#thanks-offerte .artwork {
    bottom: 30px;
    right: 30px;
    z-index: 2;
}