#sidenav {
    display: none;
    position: fixed;
    width: 300px;
    background-color: var(--dark);
    padding: 20px;
    height: calc(100vh - 40px);
}
.nav-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}
.nav-header img {
    max-width: 75px;
}
.nav-header .datetime p {
    color: var(--beige);
    margin: 10px 0px;
}
.nav-header .datetime .day {
    text-transform: capitalize;
}
.nav-header .datetime .time {
    font-family: "PrometoRegular";
}
.menu-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column; 
    width: 100%;
}
.menu-items .item {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    width: calc(100% - 40px);
    color: var(--beige);
    padding: 20px 20px;
    border-radius: 10px;
    text-decoration: none;
}
.menu-items .item.current {
    color: var(--dark);
    background-color: var(--beige);
}
.menu-items .top {
    position: relative;
    width: 100%;
    background: none;
    border: 0px;
    font-family: "PrometoMedium";
    font-size: 16px;
}
.menu-items .top .arrow {
    position: absolute;
    top: 10px;
    right: 10px;
}
.menu-items .top[aria-expanded="true"] .arrow {
    transform: rotate(90deg);
}
.menu-items .item.subitem {
    width: calc(100% - 60px);
    padding-left: 40px !important;
}
.menu-items .top[aria-expanded="true"] {
    color: var(--beige);
}
.menu-items .top:hover {
    cursor: pointer;
}
.menu-items .item svg {
    font-size: 22px;
}
.bottom {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%, 0%);
}
.bottom .logout {
    font-family: "AvertaRegular";
    color: var(--beige);
}

/* iPad Landscape */
@media only screen and (min-width: 1024px) {
    #sidenav {
        display: block;
    }
}

