.enquete {
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 100vh;
    background-color: #F4F0E0;
}
.enquete .loading {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.enquete .artwork {
    z-index: 0;
    bottom: 0px;
}
.enquete-content {
    max-width: 800px;
    padding: 0px 50px;
    margin: 0 auto;
}
.enquete-header {
    margin-bottom: 50px;
    text-align: center;
}
.enquete-header p {
    color: var(--dark);
    font-family: "AvertaRegular";
    line-height: 22px;
    margin: 35px 0px;
}
.enquete-header span {
    color: var(--dark);
    font-family: "AvertaRegular";
    font-style: italic;
    font-size: 14px;
}
.question {
    padding: 15px 0px;
    border-bottom: 1px solid #CBCBCB;
}
.question:last-child {
    border: 0px;
}
.question-contents {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}
.question-contents p {
    max-width: 80%;
    color: var(--dark);
    font-size: 20px;
}
.question.invalid .question-contents p {
    color: var(--red);
}

.enquete-footer {
    text-align: center;
    margin-top: 50px;
}
#submit-results {
    font-family: "AvertaSemiBold";
    padding: 10px 15px;
    background-color: var(--lightGreen);
    color: var(--green);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
.enquete .loader {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255,255,255,0.9);
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.enquete .loader.show {
    display: flex;
}

/* iPad Landscape */
@media only screen and (min-width: 1024px) {
    .enquete {
        padding-top: 75px;
    }
    .question {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    .question-contents {
        width: 80%;
    }
    .question-contents p {
        max-width: 80%;
    }
}