#header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.logout {
    color: var(--dark);
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-family: "AvertaRegular";
}
.logout svg {
    font-size: 20px;
}
.logout:hover {
    cursor: pointer;
}

/* iPad Landscape */
@media only screen and (min-width: 768px) {
    .logout {
        display: flex;
    }
  }