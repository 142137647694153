
#login {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    background-color: #f8fafc;
}
.login {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.loginform {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 350px;
}
.loginform img {
    max-width: 130px;
    margin-bottom: 20px;
}
.loginform p {
    color: var(--dark);
    text-align: center;
    line-height: 20px;
}
.user-login {
    margin-top: 35px;
}
.loginform input {
    text-align: center;
    width: 100%;
}
.loginform .form-field {
    box-sizing: border-box;
    margin: 10px 0px;
    padding: 15px;
    border: 0px;
    box-shadow: 0 5px 10px -5px rgb(0 0 0 / 10%), 0 5px 5px -5px rgb(0 0 0 / 4%);
    font-family: "AvertaSemiBold";
    font-size: 16px;
    outline: 0;
    color: var(--dark);
}
.loginform .form-field::placeholder {
  color: rgba(0, 30, 78, 0.25) !important;
}
.loginform .submit-form {
    margin-top: 20px;
    padding: 0px;
    font-family: "AvertaSemiBold";
    font-size: 16px;
    padding: 15px;
    color: white;
    border: 0px;
    background-color: var(--red);
}
.loginform .submit-form:hover {
    cursor: pointer;
}
.loginform .loader {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginform .forgot-password {
    display: block;
    margin-top: 50px;
    color: var(--dark);
    text-decoration: none;
    text-align: center;
}
.loginform .forgot-password:hover {
    cursor: pointer;
}
.loginform .login-button {
    width: 100%;
    max-width: 200px;
    text-align: center;
    text-decoration: none;
    margin-top: 20px;
    padding: 0px;
    font-family: "AvertaSemiBold";
    font-size: 16px;
    padding: 15px;
    color: white;
    background-color: var(--red);
}
.image {
    display: none;
}
#maintenance {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.maintenance-content {
    width: 400px;
    max-width: 90%;
    text-align: center;
}
.maintenance-content img {
    max-width: 150px;
    margin-bottom: 35px;
}

/* iPad Portrait */
@media only screen and (min-width: 768px) {

}


/* iPad Landscape */
@media only screen and (min-width: 1024px) {
    .login {
        width: 50%;
    }
    .image {
        display: block;
        width: 50%;
        height: 100%;
        background-image: url('https://www.vsdv.nl/wp-content/uploads/2023/04/vsdv-2.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
}