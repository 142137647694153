.info-blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.info-block {
    width: 100%;
}
.search-offertes form {
    position: relative;
}
.search-offertes .icon {
    position: absolute;
    top: 20px;
    left: 20px;
}
.search-offertes .loading svg {
    margin-top: -30px;
    margin-left: -5px;
}
.search-field {
    width: 100%;
    padding: 15px 20px 15px 60px;
    font-size: 24px;
    font-family: AvertaSemiBold !important;
    color: var(--dark);
    background: none;
    border: 0px;
    outline: 0;
    border-bottom: 1px solid var(--gray);
    margin-bottom: 30px;
    box-sizing: border-box;
}
.search-field:focus {
    background-color: var(--gray);
}
.offerte-results .list-item {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--gray);
    margin-bottom: 10px;
    text-decoration: none;
    color: var(--dark);
    border-radius: 10px;
}
.offerte-results .item-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 15px;
}
.offerte-results .item-content .item-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 50px;
}
.offerte-results .item-content .item-title {
    width: 450px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 15px;
}
.offerte-results .item-content .item-meta {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 50px;
}
.offerte-results .item-content .item-meta p {
    font-family: AvertaRegular !important;
}
.offerte-results .item-content .item-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 50px;
}
.offerte-results .item-content .item-actions p {
    font-style: italic;
    color: #9AA4B2;
}
.offerte-results .item-content .item-actions button {
    background-color: var(--lightGreen);
    border: 0;
    border-radius: 10px;
    color: var(--green);
    font-family: AvertaSemiBold;
    font-size: 14px;
    margin: 10px 0px;
    padding: 10px 15px;
}

@media only screen and (min-width: 1300px) {
    .info-blocks {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        gap: 25px;
    }
    .info-blocks.three .info-block {
        width: 31%;
    }
    .info-blocks.two .info-block {
        width: 47.5%;
    }
}