.block {
    margin-bottom: 25px;
}
.sub-block {
    margin: 25px 0px;
}
.client-info:hover {
    cursor: pointer;
}
.nested-two-column {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.nested-two-column input {
    width: 100%;
}
.input-field {
    position: relative;
    margin: 0px 0px 15px 0px;
}
.input-field .placeholder {
    position: absolute;
    top: 20px;
    right: 10px;
    font-size: 12px;
    font-family: "AvertaRegular";
    color: var(--dark);
    opacity: 0.5;
}
.input-field.select-field .placeholder {
    right: 80px;
}
.input-field .form-field {
    padding-right: 85px;
    margin: 0px;
}
.radio-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    color: var(--dark);
    font-family: "AvertaRegular";
}
.radio-option input:hover, .radio-option label:hover {
    cursor: pointer;
}
.checkboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.checkboxes .checkbox {
    margin: 5px 0px;
    font-family: "AvertaRegular";
    color: var(--dark);
    width: 48%
}
.toeslag {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1%;
    margin-bottom: 10px;
}
.toeslag .field {
    margin: 0px;
}
.toeslag .long {
    width: 48%;
}
.toeslag .middle {
    width: 48%;
}
.toeslag .short {
    width: 48%;
}
.maatstaf {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.maatstaf .field {
    margin: 0px;
}
.maatstaf .middle {
    width: 48%;
}
.maatstaf .short {
    width: 48%;
}
.toeslagen {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}
.toeslagen .rows {
    max-width: 90%;
}
.toeslagen .actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 10%;
    gap: 10px;
    height: 65px;
}
.toeslagen .actions button {
    font-size: 28px;
    line-height: 14px;
    padding: 0px;
    border: 0px;
    border-radius: 50px;
}
.toeslagen .actions button:hover {
    cursor: pointer;
}
.toeslagen .actions button.add svg {
    color: var(--green);
}
.toeslagen .actions button.remove svg {
    color: var(--red);
}
.zones {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
}
.zones .zone {
    width: 20%;
}
.zones .zone .name {
    text-align: center;
    color: var(--red);
    background-color: var(--secondGray);
    padding: 10px;
    border: 0.5px solid var(--secondGray);
}
.zones .zone input {
    width: calc(100% - 21px);
    border-radius: 0px;
    border: 0.5px solid var(--secondGray);
    text-align: center;
    padding: 10px;
    font-family: "AvertaRegular";
}
.zones .zone input:hover {
    border: 0.5px solid var(--red);
}
.zones .zone input:focus {
    outline: 0;
    border: 0.5px solid var(--red);
}

.checklist-block {
    position: sticky;
    top: 20px;
    width: calc(100% - 60px);
    padding: 30px;
    background-color: var(--gray);
}
.checklist-block h3 {
    margin: 0px;
}
.checklist-block .list-item {
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 5px 0px;
    margin-bottom: 5px;
    font-family: "AvertaRegular";
}
.checklist-block .list-item svg {
    font-size: 20px;
}
.checklist-block .list-item span {
    color: var(--dark);
}
.checklist-block button {
    margin-top: 25px;
    width: 100%;
    font-family: "PrometoMedium";
    padding: 10px 15px;
    background-color: var(--lightGreen);
    color: var(--green);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
.checklist-block button:hover {
    cursor: pointer;
}
.checklist-block .error-message {
    margin: 0px;
    margin-top: 25px;
    color: var(--red);
}
.select-option {
    width: 100%;
}
.checkbox {
    display: block;
    margin: 25px 0px;
}
.checkbox:hover {
    cursor: pointer;
}
.checkbox span.checkbox-label {
    color: var(--dark);
    margin-left: 10px;
}
.css-1fdsijx-ValueContainer {
    padding: 12px 10px !important;
}
.select-option  .css-1jqq78o-placeholder {
    color: rgba(0, 30, 78, 0.25) !important;
}
.select-option .css-13cymwt-control {
    border: 1px solid white;
    border-radius: 0px;
}
.select-option.valid .css-13cymwt-control {
    border: 1px solid var(--green);
}
h4 {
    font-size: 18px;
    color: var(--dark);
    margin-bottom: 10px;
}
.pac-item {
    font-family: "PrometoLight";
}
.pac-item:hover {
    cursor: pointer;
}
.pac-item-query {
    font-family: "PrometoMedium";
    color: var(--dark);
}
.pac-icon {
    fill: var(--red);
}
.show-map {
    position: absolute;
    top: 20px;
    right: 75px;
    font-family: "PrometoMedium";
    padding: 10px 15px;
    background-color: var(--lightRed);
    color: var(--red);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
.add-zone-row {
    position: absolute;
    top: 20px;
    font-family: "PrometoMedium";
    padding: 10px 15px;
    background-color: var(--lightRed);
    color: var(--red);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
.loader-offerte {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.8);
    backdrop-filter: blur(3px);
    z-index: -1;
    opacity: 0.0;
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.loader-offerte.show {
    opacity: 1.0;
    z-index: 2;
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.loader-content {
    margin: 0 auto;
    margin-top: 150px;
    max-width: 250px;
}
.loader-content p {
    text-align: center;
    color: var(--dark);
}
.add-remove {
    font-family: "PrometoMedium";
    color: var(--red);
    text-decoration: underline;
    border: none;
    background: none;
    padding: 0px;
    margin-top: 10px;
}
.side-preview {
    position: fixed;
    top: 0px;
    right: -1050px;
    bottom: 0px;
    width: 1000px;
    max-width: 90%;
    background-color: #F4F4F4;
    box-shadow: 0 14px 14px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.15);
    z-index: 3;
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    overflow: scroll;
}
.view-map.show {
    right: 0px;
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.side-preview-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.side-preview-header svg {
    color: var(--red);
    font-size: 22px;
}
.side-preview-header svg:hover {
    cursor: pointer;
}
.side-preview-content {
    padding: 20px;
}
.side-preview-content img {
    max-width: 100%;
    max-height: 85vh;
}
.react-confirm-alert-body {
    font-family: "AvertaRegular" !important;
}
.react-confirm-alert-body h1 {
    color: var(--red);
    font-size: 20px;
}
.react-confirm-alert-button-group > button {
    font-size: 16px !important;
    font-family: "PrometoMedium";
    padding: 10px 15px;
    background-color: var(--lightGreen) !important;
    color: var(--green) !important;
    border: 0px;
    border-radius: 10px;
}
.react-confirm-alert-button-group > button:last-child {
    background-color: var(--lightRed) !important;
    color: var(--red) !important;
}

/* iPad Portrait */
@media only screen and (min-width: 768px) {
    .two-column,
    .three-column {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .two-column .input-field {
        width: 49%;
    }
    .two-column .select-option {
        width: 49%;
    }
    .two-column .input-field.select-field .select-option {
        width: 100%;
    }
    .two-column .radio-group {
        width: 49%;
    }
    .two-column .checkbox {
        width: 48%;
    }
    .three-column .input-field {
        width: 32%;
    }
    .three-column .select-option {
        width: 32%;
    }
    .three-column .input-field.select-field .select-option {
        width: 100%;
    }
    .three-column .checkbox {
        width: 31%;
    }
    .nested-two-column {
        width: 49%;
    }
}

/* iPad Landscape */
@media only screen and (min-width: 1024px) {
    #offerte-form {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
    }
    .form-content {
        width: 60%;
    }
    .toeslag .long {
        width: 32%;
    }
    .toeslag .middle {
        width: 25%;
    }
    .toeslag .short {
        width: 15%;
    }
    .maatstaf .middle {
        width: 24%;
    }
    .maatstaf .short {
        width: 14%;
    }
    .checklist {
        width: 35%;
    }
}

@media only screen and (min-width: 1300px) {
    .form-content {
        width: 60%;
    }
    .checkboxes .checkbox {
        width: 31%;
    }
    .checklist {
        width: 35%;
    }
}