.voorblad-info h3 {
    margin-top: 0px;
    margin-bottom: 25px;
}
.voorblad-info p {
    color: var(--dark);
}
.voorblad-info {
    background-color: var(--gray);
}
.voorblad-info .content {
    padding: 20px;
}
.bestand {
    position: relative;
    margin-top: 25px;
    padding: 20px;
    background-color: #e7e7e7;
    border: 1px solid var(--secondGray);
}
.bestand span.label {
    font-family: "PrometoRegular";
    color: var(--red);
    font-size: 15px;
}
.bestand input {
    border: 0;
    box-sizing: border-box;
    color: var(--dark);
    font-family: PrometoMedium;
    font-size: 16px;
    margin: 10px 0;
    outline: 0;
    width: 100%;
}
.crop-container {
    position: relative;
    height: 400px;
    width: 100%;
    margin-top: 35px;
}
.preview-button {
    margin-top: 25px;
    width: 100%;
    font-family: "PrometoMedium";
    padding: 10px 15px;
    background-color: var(--lightRed);
    color: var(--red);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
    text-decoration: none;
}
.save-button {
    margin-top: 25px;
    width: 100%;
    font-family: "PrometoMedium";
    padding: 10px 15px;
    background-color: var(--lightGreen);
    color: var(--green);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}

/* iPad Landscape */
@media only screen and (min-width: 1024px) {
    .edit-voorblad {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }
    .voorblad-info {
        width: 49%;
    }
    .preview {
        width: 49%;
    }
  }