.table-header .actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.table-header .actions .red {
    margin-right: 10px;
    font-family: "AvertaSemiBold";
    padding: 10px 15px;
    background-color: var(--lightRed);
    color: var(--red);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
.table-header .actions .red:hover {
    cursor: pointer;
}
.table-header input {
    float: right;
    box-sizing: border-box;
    margin: 10px 0px;
    padding: 15px;
    border: 0px;
    font-family: "AvertaSemiBold";
    font-size: 16px;
    outline: 0;
    color: var(--dark);
    background-color: var(--gray);
}
.rdt_Table {
    border: 0px !important;
}
.rdt_TableHead {
    border: 0px !important;
}
.rdt_TableHeadRow {
    border-bottom-style: none !important;
}
.rdt_TableCol div {
    font-size: 18px;
    font-family: "AvertaRegular";
}
.rdt_TableCol div span {
    font-size: 10px;
    margin-left: 5px;
    color: var(--red);
}
.rdt_TableBody {
    border: 0px !important;
}
.rdt_TableRow {
    background-color: var(--gray) !important;
    border: 0px !important;
    margin: 3px 0px !important;
    border-radius: 10px !important;
}
.rdt_TableRow .rdt_TableCell {
    font-size: 16px;
    font-family: "AvertaRegular";
}
.rdt_TableRow .rdt_TableCell p.red {
    color: var(--red);
}
.rdt_Pagination {
    border-top-width: 0px !important;
}
.sort {
    display: none;
}
.actions .action-icon {
    margin-right: 15px;
    font-size: 18px;
    color: var(--dark);
}
.actions .action-icon.decline {
    color: var(--red);
}
.actions .action-icon.delete {
    color: var(--red);
}
.actions span:hover {
    cursor: pointer;
}
.react-tooltip {
    z-index: 99;
}
.show-map {
    position: absolute;
    top: 20px;
    right: 75px;
    font-family: "AvertaRegular";
    padding: 10px 15px;
    background-color: var(--lightRed);
    color: var(--red);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
.loader-offerte {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.8);
    backdrop-filter: blur(3px);
    z-index: -1;
    opacity: 0.0;
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.loader-offerte.show {
    opacity: 1.0;
    z-index: 100;
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.loader-content {
    margin: 0 auto;
    margin-top: 150px;
    max-width: 250px;
}
.loader-content p {
    text-align: center;
    color: var(--dark);
}
.add-remove {
    font-family: "PrometoMedium";
    color: var(--red);
    text-decoration: underline;
    border: none;
    background: none;
    padding: 0px;
    margin-top: 10px;
}
.side-preview {
    position: fixed;
    top: 0px;
    right: -1050px;
    bottom: 0px;
    width: 1000px;
    max-width: 90%;
    background-color: #F4F4F4;
    box-shadow: 0 14px 14px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.15);
    z-index: 3;
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    overflow: scroll;
}
.view-stats {
    width: 100%;
    max-width: 850px;
}
.view-stats.show {
    right: 0px;
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.side-preview-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.side-preview-header svg {
    color: var(--red);
    font-size: 22px;
}
.side-preview-header svg:hover {
    cursor: pointer;
}
.side-preview-content {
    padding: 20px;
}
.view-stats .side-preview-content {
    height: 80vh;
    overflow: auto;
}
#stats {
    margin-top: 30px;
    width: 100%;
}
#logs {
    margin-top: 10px;
    width: 100%;
}
#stats th {
    text-align: left;
    opacity: 0.5;
    font-style: italic;
    padding-bottom: 15px;
}
#logs th {
    text-align: left;
    opacity: 0.5;
    font-style: italic;
    padding-bottom: 15px;
}
#logs td:first-child {
    max-width: 400px;
}
#workflow {
    position: relative;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
#workflow li {
    position: relative;
    padding-left: 70px;
    height: 80px;
}
#workflow .icon {
    position: absolute;
    z-index: 2;
    top: -7px;
    left: 0px;
    font-size: 28px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    line-height: 62px;
    color: white;
    background-color: var(--red);
    text-align: center;
}
#workflow .done .icon {
    background-color: var(--green);
}
#workflow .disabled .icon {
    background-color: var(--secondGray);
}
#workflow .pulse {
    position: absolute;
    top: -18px;
    left: -11px;
}
#workflow li p {
    font-family: 'AvertaBold';
    color: var(--dark);
    font-size: 18px;
    margin: 0px;
}
#workflow .done p {
    color: var(--green);
}
#workflow .disabled p {
    color: var(--secondGray);
}
#workflow li span {
    display: block;
    font-family: "AvertaRegular";
}
#workflow .disabled span {
    color: var(--secondGray);
}
#workflow li .info {
    font-size: 14px;
    margin: 3px 0px;
}
#workflow li .log {
    font-size: 12px;
    color: var(--thirdGray);
}
#workflow .line {
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 24px;
    height: 100%;
    width: 2px;
    background-color: var(--secondGray);
}
#workflow .done .line {
    background-color: var(--green);
}
#workflow li:last-child .line {
    display: none;
}
.versions {
    margin-bottom: 35px;
}
.versions p {
    color: var(--thirdGray);
}
.versions .version-list {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
.versions .version-list li {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    color: var(--red);
    margin: 5px 0px;
}
.versions .version-list li span {
    font-family: "PrometoLight";
    color: var(--green);
    font-style: italic;
    margin-left: 15px;
}
.versions .version-list li:hover {
    cursor: pointer;
}
.loader-modal {
    width: 100%;
}
.loader-modal div {
    justify-content: center;
}
.popup-form .form-field {
    width: 100%;
    border: 1px solid var(--thirdGray);
}
.popup-form .select-option .css-13cymwt-control {
    border: 1px solid var(--thirdGray);
}
.popup-form  .two-column .form-field {
    width: 49%;
}
.popup-form .own-file {
    width: 100%;
    padding: 15px;
    background-color: var(--gray);
    margin-top: 15px;
    margin-bottom: 15px;
}
.popup-form .own-file p {
    color: var(--red);
    margin-top: 0px;
}
.popup-form .bijlagen {
    margin: 25px 0px;
}
.popup-form .bijlagen .bijlage {
    margin: 5px 0px;
}
.popup-form .bijlagen label {
    font-family: "PrometoRegular";
}
.popup-form  .green {
    width: 100%;
    font-family: "PrometoMedium";
    padding: 10px 15px;
    background-color: var(--lightGreen);
    color: var(--green);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
.popup-form  .green:hover {
    cursor: pointer;
}
#decline-offerte textarea {
    border: 1px solid var(--thirdGray);
}
#decline-offerte .green {
    width: 100%;
    font-family: "PrometoMedium";
    padding: 10px 15px;
    background-color: var(--lightGreen);
    color: var(--green);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
#decline-offerte .green:hover {
    cursor: pointer;
}