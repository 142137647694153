.list-menu-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column; 
    width: 100%;
}
.list-menu-items .item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    color: var(--dark);
    padding: 20px 0px;
    text-decoration: none;
    border-bottom: 1px solid lightgray;
}
.list-menu-items .item.current {
    color: var(--red);
    background-color: var(--middleRed);
}
.list-menu-items .item:last-child {
    border-bottom: 0px;
}
.list-menu-items .item svg {
    font-size: 22px;
}
.logout-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.logout-menu {
    margin-top: 75px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-family: "AvertaRegular";
    font-style: italic;
    text-align: center;
}