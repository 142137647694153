.rapportage-filters select,
.rapportage-filters input {
    font-size: 16px;
    padding: 14px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: var(--gray);
    border: 0;
    color: var(--dark);
    font-family: AvertaRegular;
}
.rapportage-filters button {
    background-color: var(--lightGreen);
    border: 0;
    border-radius: 10px;
    color: var(--green);
    font-family: AvertaSemiBold;
    font-size: 14px;
    margin-top: 5px;
    padding: 10px 15px;
}
.rapportage {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
}
.rapportage .block {
    width: 100%;
    background-color: #F5F5F5;
}
.rapportage .block-content {
    padding: 25px;
}
.rapportage .block-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}
.rapportage .block-header h3 {
    margin: 0px;
}
.rapportage .block-meta {
    font-family: AvertaRegular;
    font-size: 14px;
    font-style: italic;
}
.rapportage .download-excel {
    position: absolute;
    right: 25px;
    top: 20px;
}
.rapportage .download-excel:hover {
    cursor: pointer;
}
.rapportage .funnel-item {
    position: relative;
    margin: 10px 0px;
}
.rapportage .funnel-item .funnel-block {
    background-color: white;
    margin: 0 auto;
}
.rapportage .funnel-item .funnel-block-contents {
    padding: 10px;
    text-align: center;
}
.rapportage .funnel-item .funnel-block-contents p {
    color: var(--red);
    margin: 5px 0px;
}
.rapportage .funnel-item .funnel-block-contents .status-name {
    font-size: 12px;
    font-style: italic;
    font-family: AvertaRegular;
    color: var(--dark);
}
.rapportage .funnel-item .funnel-item-meta {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(0%, -50%);
    font-size: 12px;
    font-style: italic;
    font-family: AvertaRegular;
}
.rapportage .leads {
    max-height: 350px;
    overflow: auto;
}
.rapportage .leads .lead {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 0px;
    border-bottom: 1px solid #CBCBCB;
}
.rapportage .leads .lead p {
    margin: 0px;
}
.rapportage .leads .lead .name span {
    font-size: 12px;
    font-style: italic;
    font-family: AvertaRegular;
}
.rapportage .leads .lead .lead-meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}
.rapportage .leads .lead .lead-actions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.rapportage .leads .lead .lead-meta .lead-meta-labels {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 5px;
    text-align: right;
}
.rapportage .leads .lead .lead-meta span {
    font-size: 12px;
    font-style: italic;
    font-family: AvertaRegular;
}
.rapportage .leads .lead .new-client:hover {
    cursor: pointer;
}
.rapportage .leads .lead .lead-actions .options {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 5px;
}
.rapportage .leads .lead .lead-actions .options svg {
    outline: 0;
}
.rapportage .leads .lead .lead-actions .options svg:hover {
    cursor: pointer;
}
.rapportage .question {
    padding: 5px 0px;
}
.rapportage .question p {
    color: var(--dark);
    max-width: 75%;
}
.rapportage .total-score {
    margin: 15px 0px;
    text-align: center;
}
.rapportage .top-clients {
    max-height: 500px;
    overflow: auto;
}
.rapportage .client {
    padding: 5px 0px;
    border-bottom: 1px solid #CBCBCB;
}
.rapportage .client p {
    color: var(--dark);
}
.rapportage .client span {
    color: var(--red);
}
.rapportage .client:last-child {
    border: 0px;
}
.rapportage .block-header .form-field {
    position: absolute;
    right: 25px;
    top: 15px;
    width: auto;
}
.rapportage .info-label {
    text-align: center;
    font-size: 13px;
    font-family: "AvertaRegular";
}
.add-lead {
    position: absolute;
    right: 25px;
    top: 20px;
}
.add-lead:hover {
    cursor: pointer;
}
.dougnut {
    position: relative;
}
.conversion-rate {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 37%;
    left: 50%;
    transform: translate(-50%, 0%);
}
.conversion-rate span {
    font-size: 28px;
    color: var(--green);
}
.sorting-dropdown {
    right: 125px !important;
}
.kpi {
    text-align: center;
    overflow: hidden;
}
.kpi .current-value {
    display: none;
}
.segment-value {
    display: none;
}
.kpi select {
    margin-top: 10px;
    text-align: center;
}
.kpi-meta {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.kpi-meta .kpi-item {
    width: 49%;
    text-align: center;
    background-color: white;
    padding: 15px 0px;
}
.kpi-meta .kpi-item span {
    font-family: "AvertaRegular";
    font-style: italic;
}
.kpi-meta .kpi-item p {
    margin: 0px;
    margin-top: 5px;
    color: var(--red);
}
#lead-timeline {
    max-height: 50% !important;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}
.rs-timeline-item:not(:last-child) .rs-timeline-item-content {
    padding-bottom: 35px !important;
}
.rs-timeline-item-content span {
    display: block;
}
.rs-timeline-item-content .date {
    font-size: 13px;
    font-family: "AvertaRegular";
    font-style: italic;
}
.rs-timeline-item-content .description {
    color: var(--dark);
    margin-top: 5px;
}
.rs-timeline-item-active .rs-timeline-item-dot:before {
    background-color: var(--red) !important;
}
.rs-drawer-content form {
    margin-top: 100px;
}
.rs-drawer-content textarea {
    width: 100%;
    font-family: "AvertaRegular";
    border: 0px;
    padding: 10px;
    box-sizing: border-box;
    outline: 0;
    background-color: var(--gray);
}
.rs-drawer-content form .button {
    background-color: var(--lightGreen);
    border: 0;
    border-radius: 10px;
    color: var(--green);
    font-family: AvertaSemiBold;
    font-size: 14px;
    margin-top: 5px;
    padding: 10px 15px;
    width: 100%;
}
.rs-drawer-content form .button:hover {
    cursor: pointer;
}

/* iPad Landscape */
@media only screen and (min-width: 1024px) {
    .rapportage .block {
        width: 49.2%;
    }
    .rapportage .block.column-2-3 {
        width: 65%;
    }
    .rapportage .block.column-1-3 {
        width: 33%;
    }
    .rapportage .block.column-1-4 {
        width: 24%;
    }
    .rapportage .client {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
}