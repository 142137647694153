.sign-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px;
}
.sign-header img {
    max-width: 120px;
}
.pdf-content {
    padding: 0px 25px;
}
.pdf-viewer {
    background-color: var(--gray);
    margin: 20px 0px;
}
.viewer-content {
    padding: 20px;
}
.pdf-form {
    background-color: var(--gray);
    margin: 20px 0px;
}
.sign-form-content {
    padding: 20px;
}
.sign-form-content .form-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.sign-form-content .form-header .red {
    font-family: "AvertaSemiBold";
    padding: 10px 15px;
    background-color: var(--lightRed);
    color: var(--red);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
.sign-form-content .form-header button:hover {
    cursor: pointer;
}
.attachments-list {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
.attachments-list li {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    color: var(--red);
    margin: 10px 0px;
}
.attachments-list li:hover {
    cursor: pointer;
}
.sign-form-content h3 {
    margin: 0px;
}
.sign-form-content form {
    margin-top: 25px;
}
.sign-form-content input {
    border: 1px solid white;
}
.sign-form-content img {
    width: 100%;
}
.sign-canvas-container {
    position: relative;
    padding-bottom: 50%;
    width: calc(100% - 5px);
}
.sign-canvas {
    border: 1px dashed black !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.signing-placeholder {
    color: black;
    position: absolute;
    bottom: 10px;
    right: 10px;
    opacity: 0.3;
}
#erase {
    position: absolute;
    bottom: 10px;
    left: 15px;
    font-size: 24px;
    background: none;
    border: 0px;
    color: var(--red);
}
#erase:hover {
    cursor: pointer;
}
.sign-form-footer {
    margin-top: 20px;
}
.sign-form-footer input {
    width: 100%;
    font-family: "AvertaSemiBold";
    padding: 10px 15px;
    background-color: var(--lightRed);
    color: var(--red);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
.sign-form-footer input:hover {
    cursor: pointer;
}
.sign-form-footer .green {
    width: 100%;
    font-family: "AvertaSemiBold";
    padding: 10px 15px;
    background-color: var(--lightGreen);
    color: var(--green);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
.sign-form-footer .red {
    width: 100%;
    font-family: "AvertaSemiBold";
    padding: 10px 15px;
    background-color: var(--lightRed);
    color: var(--red);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
.sign-form-footer button:hover {
    cursor: pointer;
}
.sending-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.sending-actions button {
    width: 49% !important;
}
.side-preview {
    position: fixed;
    top: 0px;
    right: -1050px;
    bottom: 0px;
    width: 100%;
    max-width: 90%;
    background-color: #F4F4F4;
    box-shadow: 0 14px 14px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.15);
    z-index: 3;
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    overflow: scroll;
}
.decline-side {
    width: 100%;
    max-width: 650px !important;
}
.decline-side.show {
    right: 0px;
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.side-preview-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.side-preview-header svg {
    color: var(--red);
    font-size: 22px;
}
.side-preview-header svg:hover {
    cursor: pointer;
}
.side-preview-content {
    padding: 20px;
}
#decline-offerte textarea {
    width: 100%;
}
#decline-offerte .red {
    width: 100%;
    font-family: "AvertaSemiBold";
    padding: 10px 15px;
    background-color: var(--lightRed);
    color: var(--red);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
#decline-offerte .red:hover {
    cursor: pointer;
}

/* iPad Landscape */
@media only screen and (min-width: 1024px) {
    .pdf-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }
    .pdf-viewer {
        width: 49%;
    }
    .pdf-form {
        width: 49%;
    }
}