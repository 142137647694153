@font-face {
  font-family: "PrometoRegular";
  src: local("PrometoRegular");
  src: url('../src/assets/fonts/Prometo-Regular.ttf') format('truetype');
}
@font-face {
  font-family: "PrometoLight";
  src: local("PrometoLight");
  src: url('../src/assets/fonts/Prometo-Light.ttf') format('truetype');
}
@font-face {
  font-family: "PrometoLightItalic";
  src: local("PrometoLightItalic");
  src: url('../src/assets/fonts/Prometo-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: "PrometoMedium";
  src: local("PrometoMedium");
  src: url('../src/assets/fonts/Prometo-Medium.ttf') format('truetype');
}
@font-face {
  font-family: "PrometoMediumItalic";
  src: local("PrometoMediumItalic");
  src: url('../src/assets/fonts/Prometo-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: "AvertaRegular";
  src: local("AvertaRegular");
  src: url('../src/assets/fonts/Averta-Regular.ttf') format('truetype');
}
@font-face {
  font-family: "AvertaBold";
  src: local("AvertaBold");
  src: url('../src/assets/fonts/AvertaStd-Bold.ttf') format('truetype');
}
@font-face {
  font-family: "AvertaSemiBold";
  src: local("AvertaSemiBold");
  src: url('../src/assets/fonts/AvertaStd-Semibold.ttf') format('truetype');
}
@font-face {
  font-family: "AvertaSemiBoldItalic";
  src: local("AvertaSemiBoldItalic");
  src: url('../src/assets/fonts/AvertaStd-SemiboldItalic.ttf') format('truetype');
}
:root {
  --blue: #001C43;
  --lightBlue: #EAF3F8;
  /* --red: #CB0A32; */
  --red: #e10514;
  --middleRed: #E7E0E7;
  --lightRed: #FAEBED;
  --green: #00D100;
  --lightGreen: #D8F1E5;
  --orange: #FFA500;
  --lightOrange: #ffa5001f;
  --gray: #EDEDED;
  --secondGray: #E2E2E2;
  --thirdGray: #7f7f7f;
  --dark: #1D252C;
  --beige: #f8e8cf;
}
html {
  margin: 0px;
  padding: 0px;
  scroll-padding-top: 35px;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0px;
  font-family: "AvertaRegular";
}
h1 {
  font-family: "AvertaBold";
  color: var(--dark);
  font-weight: normal;
}
h2 {
  font-family: "AvertaBold";
  color: var(--dark);
  font-weight: normal;
}
h3 {
  font-family: "AvertaBold";
  color: var(--dark);
  font-weight: normal;
}
textarea {
  resize: none;
}
input[type='checkbox'] {
  accent-color: var(--red);
}
input[type="date" i] {
  -webkit-appearance: none;
  -moz-appearance: none;
}
button {
  -webkit-appearance: none;
}
button:hover {
  cursor: pointer;
}
#main {
  min-height: 100vh;
  position: relative;
  /* overflow: hidden; */
}
.container {
  width: 100%;
  max-width: 1310px;
  margin: 0 auto;
}
.content {
  padding-top: 50px;
  padding-bottom: 50px;
}
.error {
  color: var(--red) !important;
}
.dashboard-content {
  padding: 20px 20px 100px 20px;
}
.blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 35px;
}
.block {
  position: relative;
}
.block .content {
  background-color: #F4F4F4;
  padding: 20px;
}
.block h3 {
  margin: 0px;
  font-size: 22px;
}
.block-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.block-header svg {
  font-size: 28px;
  color: var(--red);
}
.block .loader-deleting {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255,255,255,0.8);
}
.block .loader-deleting svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#info tr {
  vertical-align: baseline;
}
#info tr td {
  color: var(--dark);
  min-width: 80px;
}
#info tr td {
  padding: 5px 0px;
}
#info tr td:first-child {
  font-family: "AvertaRegular";
}
#info tr td:last-child {
  padding-left: 35px;
}
#info tr.log td {
  font-size: 14px !important;
  font-style: italic !important;
}
#info tr.log td svg {
  color: var(--red);
  margin-left: 10px;
}
#info tr.log td svg:hover {
  cursor: pointer;
}
.artwork {
  position: fixed;
  bottom: 80px;
  right: -50px;
  max-width: 40%;
  z-index: -1;
}
.help-button {
  position: absolute;
  top: 40px;
  right: 20px;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  font-family: "AvertaRegular";
  padding: 10px 15px;
  background-color: var(--lightRed);
  color: var(--red);
  border: 0px;
  border-radius: 10px;
}
.help-button:hover {
  cursor: pointer;
}

/* Form styling */
.form-field {
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0px;
  padding: 15px;
  border: 0px;
  font-family: "AvertaRegular";
  font-size: 16px;
  outline: 0;
  color: var(--dark);
}
.form-field.valid {
  border: 1px solid var(--green);
}
.form-field::placeholder {
  color: rgba(0, 30, 78, 0.25) !important;
}
.submit-form {
  font-family: "AvertaSemiBold";
  padding: 10px 15px;
  background-color: var(--lightGreen);
  color: var(--green);
  border: 0px;
  border-radius: 10px;
  font-size: 16px;
}
.submit-form:hover {
  cursor: pointer;
}
.form-footer {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.rodal-dialog {
  position: relative !important;
  height: auto !important;
  top: 75px !important;
}
#add-log {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
#add-log .field {
  flex-grow: 1;
  padding: 15px 150px 15px 10px;
  font-family: "AvertaRegular";
  border: 1px solid var(--secondGray);
  outline: 0;
}
#add-log .add-log-actions {
  position: absolute;
  top: 25px;
  right: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
#add-log .button {
  font-family: "AvertaSemiBold";
  padding: 10px 15px;
  background-color: var(--lightRed);
  color: var(--red);
  border: 0px;
  border-radius: 10px;
  font-size: 14px;
}
#add-log .button:hover {
  cursor: pointer;
}

/* iPad Portrait */
@media only screen and (min-width: 768px) {
  .help-button {
    right: 170px;
  }
}

/* iPad Landscape */
@media only screen and (min-width: 1024px) {
  .dashboard-content {
    flex: 1;
    padding: 35px 35px 35px 380px;
  }
  .artwork {
    bottom: 0px;
  }
}

@media only screen and (min-width: 1100px) {
  .help-button {
    top: 55px;
  }
}
