.table-contents {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 30px;
}
.table-rows {
    width: 20%;
}
.table-prices {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}
.table-prices {
    width: 80%;
}
.price-tip {
    margin-bottom: 35px;
}
.price-tip span {
    color: var(--red);
}
.column-header {
    text-align: center;
    border: 1px solid var(--secondGray);
    min-height: 200px;
}
.label-cell {
    height: 32px;
    text-align: center;
    border: 0.7px solid var(--secondGray);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.label-cell p {
    margin: 8px;
    font-style: italic;
    color: var(--red);
}
.table-rows .column-header {
    text-align: left;
    display: grid;
    align-content: end;
}
.table-rows .column-header div {
    margin-left: 10px;
    margin-bottom: 10px;
}
.table-rows .column-header p {
    font-size: 14px;
    margin: 1px 5px;
    opacity: 0.5;
}
.action {
    font-family: "AvertaBold";
    padding: 10px 15px;
    background-color: var(--lightRed);
    color: var(--red);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
    margin-right: 10px;
}
.prices-ftl {
    margin-top: 35px;
}
.prices-ftl .actions {
    text-align: center;
}
.prices-ftl .remove-column {
    color: var(--red);
    font-size: 20px;
}
.prices-ftl .remove-column:hover {
    cursor: pointer;
}
.prices-ftl tr:nth-child(2) td {
    background-color: var(--dark) !important;
    color: white !important;
}
.row-columns.names .table-row-item span {
    color: var(--red);
}
.row-columns .table-row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}
.row-columns .table-row .add-row {
    position: absolute;
    left: -35px;
    top: 0px;
    color: var(--green);
    opacity: 0.0;
    padding: 8px;
    outline: 0;
}
.row-columns .table-row .remove-row {
    position: absolute;
    left: -60px;
    top: 0px;
    color: var(--red);
    opacity: 0.0;
    padding: 8px;
    outline: 0;
}
.row-columns .table-row:hover .add-row {
    opacity: 1.0;
}
.row-columns .table-row .add-row:hover {
    cursor: pointer;
    opacity: 1.0;
}
.row-columns .table-row:hover .remove-row {
    opacity: 1.0;
}
.row-columns .table-row .remove-row:hover {
    cursor: pointer;
    opacity: 1.0;
}
.row-columns .table-row-item {
    position: relative;
    overflow: hidden;
}
.row-columns .table-row-item input {
    user-select: all;
    font-family: "PrometoMedium";
    font-size: 16px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    border: 0px;
    padding: 0px;
    margin: 0px;
}
.row-columns .table-row .table-row-item:hover {
    cursor: default;
}
.row-columns .table-row.editable .table-row-item input:hover {
    cursor: auto;
}
.row-columns .table-row-item input:focus {
    outline: none;
}
.row-columns.one .table-row-item {
    width: 100%;
}
.row-columns.two .table-row-item {
    width: 50%;
}
.row-columns.three .table-row-item {
    width: 33.33%;
}
.row-columns.four .table-row-item {
    width: 25%;
}
.table-rows .table-row-item {
    height: 32px;
    display: grid;
    align-items: center;
    text-align: center;
    border: 0.7px solid var(--secondGray);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.table-prices .column-header {
    background-color: #f6f6f6;
    overflow: auto;
}
.column-header p {
    margin: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Spreadsheet {
    width: 100%;
}
.Spreadsheet__table {
    width: 100%;
}
.Spreadsheet__table .Spreadsheet__cell, .Spreadsheet__table .Spreadsheet__header {
    text-align: center !important;
    min-height: 32px !important;
    height: 32px !important;
    max-height: 32px !important;
}
.prices-ftl .Spreadsheet__table .Spreadsheet__cell, .prices-ftl .Spreadsheet__table .Spreadsheet__header {
    text-align: left !important;
}
.save {
    margin-top: 25px;
    font-family: "PrometoMedium";
    padding: 10px 15px;
    background-color: var(--lightGreen);
    color: var(--green);
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
}
.saving-prices {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.8);
    backdrop-filter: blur(3px);
    z-index: -1;
    opacity: 0.0;
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.saving-prices.show {
    opacity: 1.0;
    z-index: 2;
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.saving-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-top: 150px;
    max-width: 250px;
}
.saving-content p {
    text-align: center;
    color: var(--dark);
}